<template>
  <section id="patients">
    <b-card no-body>
      <!-- <h4 class="mt-2 mr-2 ml-2">Search</h4> -->
      <b-form @submit.prevent>
        <b-row class="mt-1 mr-1 mb-1 ml-1">
          <b-col md="4">
              <b-form-group
                label="Name"
                label-for="inputName"
                label-cols-md="12"
                
              >
              <b-form-input id="inputName" placeholder="Search by name" v-model="query.name"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
              <b-form-group
                label="NHS Number"
                label-for="inputNhsNumber"
                label-cols-md="12"
                >
                <b-form-input
                v-model="query.nhsno"
                id="inputNhsNumber"
                placeholder="Search by NHS Number"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
              <b-form-group
                label="Postcode"
                label-for="inputPostcode"
                label-cols-md="12"
              >
              <b-form-input
                id="inputPostcode"
                placeholder="Search by postcode"
                v-model="query.postal"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
              <b-form-group
                label="Date of birth"
                label-for="inputDate"
                label-cols-md="12"
              >
              <cleave
                id="inputDate"
                v-model="query.dob"
                class="form-control"
                :raw="false"
                :options="options.date"
                placeholder="DD-MM-YYYY"
                />
              </b-form-group>
            </b-col>
            
            <b-col md="4">
              <b-form-group
              label="Gender"
              label-for="inputGender"
              label-cols-md="12"
              >
              <v-select
                v-model="query.gender"
                id="inputGender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionGender"
                placeholder="Filter by gender"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col md="4">
              <b-form-group
                label=""
                label-for="inputButtons"
                label-cols-md="12"
              >            
            <b-button
              id="inputButtons"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="loadPatients"
            >
              Search
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="resetFilter"
            >
              Reset
            </b-button>
              </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!-- Patients Table Card -->
    <b-card no-body>
      <h4 class="mt-2 mr-2 mb-2 ml-2">Search results</h4>
      <patients-table :table-data="patients" />
    </b-card>
    <!--/ Patients Table Card -->
  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import PatientsTable from "./PatientsTable.vue";

import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

export default {
  data() {
    return {
      //selected: { title: 'Square' },
      query:{
        name:'',
        postal:'',
        nhsno:'',
        dob:'',
      },
      dobValue: "",
      dobFormatted: "",
      dobSelected: "",
      optionGender: [{ title: "Male", value:'male' }, { title: "Female", value:'female' }, { title: "Unknown", value:'unknown' }],
      form: {
        date: null,
      },
      options: {
        date: {
          date: true,
          delimiter: "-",
          datePattern: ["d", "m", "Y"],
        },
      },
      patients: [],
      data: {
        // patientResults: [
        //   {
        //     id: 1,
        //     firstname: "Jim",
        //     surname: "Peterson",
        //     nhsNumber: "89723923",
        //     postcode: "GU16 7HF",
        //     dob: "1981-04-16",
        //     gender: "M",
        //   },
        //   {
        //     id: 2,
        //     firstname: "Sandra",
        //     surname: "Williams",
        //     nhsNumber: "32723955",
        //     postcode: "GU16 7HF",
        //     dob: "1990-03-10",
        //     gender: "F",
        //   },
        //   {
        //     id: 3,
        //     firstname: "Clarke",
        //     surname: "Johnson",
        //     nhsNumber: "14723979",
        //     postcode: "GU16 7HF",
        //     dob: "1965-01-30",
        //     gender: "F",
        //   },
        // ],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    Cleave,
    PatientsTable,
  },
  directives: {
    Ripple,
  },
  methods: {
    resetFilter() {
      this.query = {
        name:'',
        postal:'',
        nhsno:'',
        dob:'',
        gender:'',
      }
    },
    async loadPatients() {
      const res = await this.callApi('post', '/patients/search', this.query);
      //console.log(res)
      this.patients = res.data.result
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.dobSelected = ctx.selectedYMD;
    },
  },
  mounted() {
    //this.loadPatients();
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.col-form-label{min-height: 2.45rem;}
</style>