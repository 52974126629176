<template>
    <b-table
      v-if="tableData"
      hover
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- id --> 
      <template #cell(id)="data">
        <div class="font-weight-bolder">
          {{ data.item.id }}
        </div>
      </template>
      
      <!-- name -->
      <template #cell(name)="data">
        <router-link :to="'/patient/'+data.item.guid">{{ data.item.name }}</router-link>
      </template>

      <!-- NHS Number -->
      <template #cell(nhsNumber)="data">
        {{ data.item.nhsno }}
      </template>

      <!-- Postcode -->
      <template #cell(postcode)="data">
        {{ data.item.postal }}
      </template>

      <!-- DOB -->
      <template #cell(dob)="data">
        {{ data.item.dob }}
      </template>

      <!-- gender -->
      <template #cell(gender)="data">
        {{ data.item.gender }}
      </template>

    </b-table>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        //{ key: 'id', label: 'ID' },
        { key: 'name', label: 'NAME' },
        { key: 'nhsno', label: 'NHS NUMBER' },
        { key: 'postal', label: 'POSTCODE' },
        { key: 'dob', label: 'DOB' },
        { key: 'gender', label: 'GENDER' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
